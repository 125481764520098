
import CheckIcon from "@/components/icon/CheckIcon.vue";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: { CheckIcon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const reference = ref<string>("");

    onBeforeMount(() => {
      if (localStorage.getItem("status") !== "confirmed") {
        return router.push({ name: "Home" });
      }

      reference.value = String(route.params.reference);
      localStorage.removeItem("status");
    });

    return { reference };
  },
});
