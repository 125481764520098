<template>
  <b-title>
    <check-icon class="icon"></check-icon>
    {{ $t("complement.confirmed.title") }}
  </b-title>
  <b-information>
    <div class="confirmed_title">
      <strong>
        {{ $t("confirmed.your_reference_number") }}
        {{ reference }}
      </strong>
    </div>
    <div v-html="$t('complement.confirmed.information')"></div>
  </b-information>
  <b-container center>
    <template v-if="$i18n.locale === 'fr'">
      <a href="https://www.acs-ami.com/fr/blog/">
        <img src="@/assets/acs_blog.jpg" alt="ACS" />
      </a>
    </template>
    <template v-if="$i18n.locale === 'en'">
      <b-btn href="https://www.acs-ami.com/en">Go to ACS homepage</b-btn>
    </template>
  </b-container>
</template>

<script lang="ts">
import CheckIcon from "@/components/icon/CheckIcon.vue";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: { CheckIcon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const reference = ref<string>("");

    onBeforeMount(() => {
      if (localStorage.getItem("status") !== "confirmed") {
        return router.push({ name: "Home" });
      }

      reference.value = String(route.params.reference);
      localStorage.removeItem("status");
    });

    return { reference };
  },
});
</script>

<style lang="scss" scoped>
.confirmed_title {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}
</style>
